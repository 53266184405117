import React ,{Component} from 'react';
import {Link} from "react-router-dom";

 

class Footer extends Component {
   render(){


  return (
     
     <div>
 

   {/* Footer */}
   <footer className="footer">
    <div className="container">
      <div className="row">
        {/* Footer Column */}
        <div className="col-lg-6 footer_column">
          <div className="footer_col">
            <div className="footer_content footer_about">
              <div className="logo_container footer_logo">
                <div className="logo"><a href="/"><img width="30px" src="https://res.cloudinary.com/mhmd/image/upload/v1557368579/logo_iqjuay.png" alt="" />Annuaire</a></div>
              </div>
              <p className="footer_about_text">Ce site vous présente les différentes structures, lieux et institutions en Algérie à travers chaque Wilaya et il vous est facile de trouver et de connaître chaque Wilaya et region dans ses différents départements.</p>
              <ul className="footer_social_list">
                <li className="footer_social_item"><a href="/"><i className="fa fa-instagram" /></a></li>
                <li className="footer_social_item"><a href="/"><i className="fa fa-facebook-f" /></a></li>
                
              </ul>
            </div>
          </div>
        </div>
        {/* Footer Column */}
        
        {/* Footer Column */}
        <div className="col-lg-3 footer_column">
          <div className="footer_col">
            <div className="footer_title">Wilaya</div>
            <div className="footer_content footer_tags">
              <ul className="tags_list clearfix">
                <li className="tag_item"><a href="/">Alger</a></li>
                <li className="tag_item"><a href="/">Oran</a></li>
                <li className="tag_item"><a href="/">Bejaia</a></li>
                <li className="tag_item"><a href="/">Skikda</a></li>
                <li className="tag_item"><a href="/">Tipaza</a></li>
                <li className="tag_item"><a href="/">Chlef</a></li>
              </ul>
            </div>
          </div>
        </div>
        {/* Footer Column */}
        <div className="col-lg-3 footer_column">
          <div className="footer_col">
            <div className="footer_title">contact</div>
            <div className="footer_content footer_contact">
              <ul className="contact_info_list">
                <li className="contact_info_item d-flex flex-row">
                  <div><div className="contact_info_icon"><img src="images/placeholder.svg" alt="" /></div></div>
                  <div className="contact_info_text">Algérie - Alger</div>
                </li>
                <li className="contact_info_item d-flex flex-row">
                  <div><div className="contact_info_icon"><img src="images/phone-call.svg" alt="" /></div></div>
                  <div className="contact_info_text"> +(213) 658 761 936</div>
                </li>
                <li className="contact_info_item d-flex flex-row">
                  <div><div className="contact_info_icon"><img src="images/message.svg" alt="" /></div></div>
                  <div className="contact_info_text"><a href="mailto:contactme@gmail.com?Subject=Hello" target="_top">contact@lncalgerie.com</a></div>
                </li>
                <li className="contact_info_item d-flex flex-row">
                  <div><div className="contact_info_icon"><img src="images/planet-earth.svg" alt="" /></div></div>
                  <div className="contact_info_text"><a href="https://lncalgerie.com/">Ln Consulting</a></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* Copyright */}
  <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 order-lg-1 order-2  ">
          <div className="copyright_content d-flex flex-row align-items-center">
            <div>{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            Tous les droits sont réservés | <a href="https://lncalgerie.com" target="_blank">Ln Consulting</a>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}</div>
          </div>
        </div>
        <div className="col-lg-6 order-lg-2 order-1">
          <div className="footer_nav_container d-flex flex-row align-items-center justify-content-lg-end">
            <div className="footer_nav">
              <ul className="footer_nav_list">
                <li className="footer_nav_item"><Link  to='/'>Accueil <span className="sr-only">(current)</span></Link></li>
                <li className="footer_nav_item"><Link   to='/test'>Annuiare</Link></li>
                <li className="footer_nav_item"><a href="contact.html">contact</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
  
  );
}
}

export default Footer;
