import React ,{Component} from 'react';
 

 

class Contact extends Component {

  state = {
    name:'',
    email : '',
    message : ''
  }

  sub =(e)=>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  onsub=(event)=>{
    event.preventDefault();

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: {  },
      body:  JSON.stringify({ name: this.state.name , email : this.state.email , message : this.state.message })
  };
  fetch('https://digibled.com/api_test/api/contact', requestOptions)
      .then(response => response.json())
      .then(data => console.log(data));

  }

  componentDidMount() {
    // // Simple POST request with a JSON body using fetch
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {  },
    //     body:  JSON.stringify({ test: 'React POST Request Example' })
    // };
    // fetch('https://digibled.com/api_test/api/contact', requestOptions)
    //     .then(response => response.json())
    //     .then(data => console.log(data));
}

   render(){


  return (
     
    <div>

<div className="super_container">
      
    {/* Home */}
    <div className="home">
      <div className="home_background parallax-window" data-parallax="scroll" data-image-src="images/contact_background.jpg" />
      <div className="home_content">
        <div className="home_title">contact</div>
      </div>
    </div>





     {/* About */}
<div className="about">
  <div className="container">
    <div className="row">
      
      <div className="col-lg-12">
        {/* About Info */}
        <div className="about_info">
          <ul className="contact_info_list">
            <li className="contact_info_item d-flex flex-row">
              <div><div className="contact_info_icon"><img src="images/placeholder.svg" alt="" /></div></div>
              <div className="contact_info_text">Algerie - Alger Centre</div>
            </li>
            <li className="contact_info_item d-flex flex-row">
              <div><div className="contact_info_icon"><img src="images/phone-call.svg" alt="" /></div></div>
              <div className="contact_info_text">+(213) 658 761 936</div>
            </li>
            <li className="contact_info_item d-flex flex-row">
              <div><div className="contact_info_icon"><img src="images/message.svg" alt="" /></div></div>
              <div className="contact_info_text"><a href="mailto:contactme@gmail.com?Subject=Hello" target="_top">contact@lncalgerie.com</a></div>
            </li>
            <li className="contact_info_item d-flex flex-row">
              <div><div className="contact_info_icon"><img src="images/planet-earth.svg" alt="" /></div></div>
              <div className="contact_info_text"><a href="https://lncalgerie.com">www.lncalgerie.com</a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>




    {/* Contact */}
    <div className="contact_form_section">
      <div className="container">
        <div className="row">
          <div className="col">
            {/* Contact Form */}
            <div className="contact_form_container">
              <div className="contact_title text-center">Contactez Nous</div>
              <form onSubmit={this.onsub}  id="contact_form" className="contact_form text-center">
                <input type="text" onChange={this.sub} name="name" id="contact_form_name" defaultValue="" className="contact_form_name input_field" placeholder="Name" required="required" data-error="Name is required." />
                <input type="text" onChange={this.sub} name="email" id="contact_form_email" className="contact_form_email input_field" placeholder="E-mail" required="required" data-error="Email is required." />
                {/* <input type="text" id="contact_form_subject" className="contact_form_subject input_field" placeholder="Subject" required="required" data-error="Subject is required." /> */}
                <textarea id="contact_form_message" className="text_field contact_form_message" name="message" onChange={this.sub} rows={4} placeholder="Message" required="required" data-error="Please, write us a message." defaultValue={""} />
                <button type="submit" id="form_submit_button" className="form_submit_button button trans_200">Envoyer le message<span /><span /><span /></button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


 {console.log("c *" + this.state.name + " ma *"+this.state.email + "message" + this.state.message)}

    </div>

  </div>
  
  
  );
}
}

export default Contact;
