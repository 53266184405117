import React ,{Component} from 'react';
import { BrowserRouter as Router,  Switch ,Route} from "react-router-dom";

import Nav from './Nav';
import Home from './Home';
import Annuaire from './Annuaire';
import Page from './Page';
import Contact from './Contact';
import Footer from './Footer';
import Product from './Product';


 


class App extends Component {
   render(){

  return (

    <Router>
    <div className="App">
     
     <Nav />

     <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/test" exact component={Annuaire} />
          <Route path="/test/:id/:cat/:page?" exact component={Annuaire} />
          <Route path="/page/:id" exact component={Page} />
          <Route path="/product/:id" exact component={Product} />
          <Route path="/contact" exact component={Contact} />
     </Switch>

     <Footer/>
    </div>
    </Router>
  );
}
}

export default App;
