import React ,{Component} from 'react';
import {Link} from "react-router-dom";

 

class Nav extends Component {
   render(){


  return (
     
     <div>
  {/* NAVBAR*/}
  <nav className="navbar navbar-expand-lg py-3 navbar-dark  shadow-sm">
    <div className="container">
      <a href="/" className="navbar-brand">
        {/* Logo Image */}
        <img src="/images/logo.png" width={45} alt="" className="d-inline-block align-middle mr-2" />
        {/* Logo Text */}
        <span className="text-uppercase font-weight-bold"> </span>
      </a>
      <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler"><span className="navbar-toggler-icon" /></button>
      <div id="navbarSupportedContent" className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active"> <Link  className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to='/'>Accueil <span className="sr-only">(current)</span></Link></li>
          <li className="nav-item"> <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to='/test'>Annuaire</Link></li>
          <li className="nav-item"><Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to='/contact'>Contact</Link></li>
          <li className="nav-item btn-log"><a target="_blank" href="https://alpaja.com/annuaire_api/register.php">Créer un compte</a></li>
          <li className="nav-item btn-log"><a target="_blank" href="https://alpaja.com/annuaire_api/login.php">Se connecter</a></li>
          
        </ul>
      </div>
    </div>
  </nav>
  



</div>
  
  );
}
}

export default Nav;
