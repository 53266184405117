import React ,{Component} from 'react';

import './styles/single_listing_styles.css';
import './styles/single_listing_responsive.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import Toast from 'light-toast';
import parse from 'html-react-parser';




var tei=[];
const images = [
  // {
  //   original: 'https://picsum.photos/id/1018/1000/600/',
  //   thumbnail: 'https://picsum.photos/id/1018/250/150/',
  // },
  // {
  //   original: 'https://picsum.photos/id/1015/1000/600/',
  //   thumbnail: 'https://picsum.photos/id/1015/250/150/',
  // },
  // {
  //   original: 'https://picsum.photos/id/1019/1000/600/',
  //   thumbnail: 'https://picsum.photos/id/1019/250/150/',
  // },
];

// const options = {
//   margin: 30,
//   responsiveClass: true,
//   loop: true,
//   nav: true,
//   dots: false,
//   autoplay: true,
//   // navText: ["Prev", "Next"],
//   smartSpeed: 1000,
//   items: 1,
//   height: 200,
// };

const options = {
  margin: 30,
  responsiveClass: true,
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  navText: ['<div class="home_slider_nav home_slider_prev"> <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"> <defs> <linearGradient id="home_grad_prev"> <stop offset="0%" stop-color="#fa9e1b"></stop> <stop offset="100%" stop-color="#8d4fff"></stop> </linearGradient> </defs> <path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path> <polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219 11.042,18.219 "></polygon> </svg> </div>', '<div class="home_slider_nav home_slider_next"> <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"> <defs> <linearGradient id="home_grad_next"> <stop offset="0%" stop-color="#fa9e1b"></stop> <stop offset="100%" stop-color="#8d4fff"></stop> </linearGradient> </defs> <path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path> <polygon class="nav_arrow" fill="#F3F6F9" points="13.044,11.551 11.71,12.885 15.714,16.888 11.71,20.891 13.044,22.224 18.379,16.888 17.048,15.554 17.046,15.554 "></polygon> </svg> </div>'],
  smartSpeed: 1000,
  items: 1,
  height: 200,
};

const optionscta = {
  margin: 30,
  responsiveClass: true,
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  navText: ['<div class="cta_slider_nav cta_slider_prev"><svg version="1.1" id="Layer_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="cta_grad_prev"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219  11.042,18.219 "></polygon></svg></div>', "Next"],
  smartSpeed: 1000,
  items: 1,
  height: 200,
};


const optionstesm = {
  loop:true,
				nav:true,
				dots:false,
				smartSpeed:1200,
        margin:30,
        navText: ['<div class="test_slider_nav test_slider_prev"><svg version="1.1" id="Layer_6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="test_grad_prev"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219  11.042,18.219 "></polygon></svg></div>', '<div class="test_slider_nav test_slider_next"><svg version="1.1" id="Layer_7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="test_grad_next"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="13.044,11.551 11.71,12.885 15.714,16.888 11.71,20.891 13.044,22.224 18.379,16.888 17.048,15.554  17.046,15.554 "></polygon></svg></div>'],
				responsive:
				{
					0:{items:3},
					480:{items:3},
					768:{items:6},
					992:{items:8}
				}
};

const optionstesmtwo = {
  loop:true,
				nav:true,
				dots:false,
				smartSpeed:1200,
        margin:30,
        navText: ['<div class="test_slider_nav test_slider_prev"><svg version="1.1" id="Layer_6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="test_grad_prev"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219  11.042,18.219 "></polygon></svg></div>', '<div class="test_slider_nav test_slider_next"><svg version="1.1" id="Layer_7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="test_grad_next"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="13.044,11.551 11.71,12.885 15.714,16.888 11.71,20.891 13.044,22.224 18.379,16.888 17.048,15.554  17.046,15.554 "></polygon></svg></div>'],
				responsive:
				{
					0:{items:1},
					480:{items:1},
					768:{items:2},
					992:{items:3}
				}
};

// const urlapi = "http://192.168.43.14/annuaire_api";
const urlapi = "https://alpaja.com/annuaire_api";
// const urlapi = "http://192.168.1.10/annuaire_api";
// const urlapi = "http://192.168.1.36/annuaire_api";



// https://digibled.com/api_test/api/itemsone/${id}
// https://digibled.com/api_test

var prestation=[];
var call='';
var Proximite = '';

var percentChange='';

function calculatePercentage( oldFigure,  newFigure)
{
     percentChange = (( oldFigure -  newFigure) /  oldFigure) * 100;
    return percentChange;
}

var d = new Date();
var daten =['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
var danow=new Date().toLocaleTimeString("en-US", {timeZone: "Africa/Algiers", hour12: false}).replace(/(.*)\D\d+/, '$1');

class Page extends Component {

  state = {
    isLoading: true,
    users: [],
    error: null,
    img : null,
    email : '',
    message : '',
    rate : 5,

  };

  fetchUsers() {
    const id = this.props.match.params.id;
    console.log(id);
    fetch(`${urlapi}/api/itemsone/${id}`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          users: data,
          isLoading: false,
          img: data.img
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.fetchUsers();
    // this.Button();
  }

  sub =(e)=>{
    this.setState({
      [e.target.name] : e.target.value
    })

  }
  

  onsub=(event)=>{
    event.preventDefault();
    console.log(this.state.email +  "    " + this.state.message  +  "   " + this.state.rate)


    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: {  },
      body:  JSON.stringify({ name: this.props.match.params.id , email : this.state.email , message : this.state.message , rate : this.state.rate })
  };
  fetch(urlapi+'/api/comment', requestOptions)
      .then(response => response.json())
      .then(data => data.flag == "true" ? Toast.info( <div><img width={80} src={"https://cdn.iconscout.com/icon/free/png-256/right-true-verify-perfect-trust-64-32776.png"}/><br></br><p>Votre commentaire a été envoyé avec succès</p></div>, 3000, () => {
        // do something after the toast disappears
      }) : '' );

      this.setState({
        
        email :'',
        message : ''
         
         
      })

    this.fetchUsers();


      // if (data.flag =="true") {
      //   Toast.info( <div><img width={80} src={"https://cdn.iconscout.com/icon/free/png-256/right-true-verify-perfect-trust-64-32776.png"}/><br></br><p>Votre commentaire a été envoyé avec succès</p></div>, 3000, () => {
      //     // do something after the toast disappears
      //   })
      // }

      // Toast.info('message...', 3000, () => {
      //   // do something after the toast disappears
      // })

  }


  //  Button = () => (
      
  //       Toast.info( <div><img width={80} src={"https://cdn.iconscout.com/icon/free/png-256/right-true-verify-perfect-trust-64-32776.png"}/><br></br><p>Votre commentaire a été envoyé avec succès</p></div>, 3000, () => {
  //         // do something after the toast disappears
  //       })
      
  // );

   render(){
    const { isLoading, users, error } = this.state;
    const imgone =['2.jpg','29.jpg','46.jpg','64.jpg','68.jpg','40.jpg','69.jpg','58.jpg','59.jpg','53.jpg','80.jpg'];
const imgtwo =['3.jpg','8.jpg','18.jpg','62.jpg','66.jpg','42.jpg','60.jpg','48.jpg','51.jpg','54.jpg'];
const imgth = ['20.jpg','22.jpg','29.jpg','30.jpg','39.jpg','44.jpg','45.jpg','56.jpg'];

     


  return (
     <div>

 

<div>
  {/* Home */}
  {!isLoading ? (
      
      
      this.state.users.random.length && (
  <div className="ads-page home_slider_annuaire">
  <OwlCarousel {...options}  className="owl-theme"  margin={8} >  

  {users.random.map(user => {
            const { id, name ,resum,mail, img , title,text,adr,tel} = user;
            return (
                 
                  <div>
           <div className="home_slider_content text-center">

            <div className="home_slider_content_inner" data-animation-in="flipInX" data-animation-out="animate-out fadeOut">
            <h1> {name} </h1>
            {/* <h1>L'algérie</h1> */}
            <div className="button home_slider_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>
            
            </div>
            <img className="img" src= {img !=null ? urlapi+"/images/news/"+ img : 'https://i.stack.imgur.com/y9DpT.jpg'}/>
            </div>  
            </div>
            );
          })}

          
          

             


             

         
      </OwlCarousel>
    {/* <div className="home_background parallax-window" data-parallax="scroll" data-image-src="images/about_background.jpg" />
    <div className="home_content">
      <div className="home_title">{!isLoading ? (
            this.state.users.data[0].name  
        ) : (
           'Loading...' 
        )} </div>

 

    </div> */}
  </div>

)) : (
  'Loading...' 
)}

 
  {/* Offers */}
  {/* <div className="offers">

     
    <div className="container">
      <div className="row">
        <div className="col-lg-1 temp_col" />
        <div className="col-lg-11">
           
          <div className="offers_sorting_container">
            <ul className="offers_sorting">
              <li>
                <span className="sorting_text">price</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;original-order&quot; }" data-parent=".price_sorting"><span>show all</span></li>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;price&quot; }" data-parent=".price_sorting"><span>ascending</span></li>
                </ul>
              </li>
              <li>
                <span className="sorting_text">location</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;original-order&quot; }"><span>default</span></li>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;name&quot; }"><span>alphabetical</span></li>
                </ul>
              </li>
              <li>
                <span className="sorting_text">stars</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="filter_btn" data-filter="*"><span>show all</span></li>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;stars&quot; }"><span>ascending</span></li>
                  <li className="filter_btn" data-filter=".rating_3"><span>3</span></li>
                  <li className="filter_btn" data-filter=".rating_4"><span>4</span></li>
                  <li className="filter_btn" data-filter=".rating_5"><span>5</span></li>
                </ul>
              </li>
              <li className="distance_item">
                <span className="sorting_text">distance from center</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="num_sorting_btn"><span>distance</span></li>
                  <li className="num_sorting_btn"><span>distance</span></li>
                  <li className="num_sorting_btn"><span>distance</span></li>
                </ul>
              </li>
              <li>
                <span className="sorting_text">reviews</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="num_sorting_btn"><span>review</span></li>
                  <li className="num_sorting_btn"><span>review</span></li>
                  <li className="num_sorting_btn"><span>review</span></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-12">
           
          <div className="offers_grid">
             

            
            <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        {!isLoading ? (
          users.data.map(user => {
            const { id,resum,mail, img ,adr,tel} = user;
            return (
              <div key={id}>{console.log(this.state.users.data[0].adr)}
                 



                <div className="offers_item rating_4">
              <div className="row">
                <div className="col-lg-1 temp_col" />
                <div className="col-lg-3 col-1680-4">
                  <div className="offers_image_container">
                     
                    <div className="offers_image_background" style={{backgroundImage: 'url(https://digibled.com/api_test/store/admin/admin/images/news/' + img + ')'}} />
                    <div className="offertm_name"><a href="single_listing.html"> {tel} </a></div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="offers_content">
            <div className="offers_price">{adr}</div>
                    <div className="rating_r rating_r_4 offers_rating" data-rating={4}>
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                    </div>
            <p className="offers_text"> {resum}</p>
                     
                    <div className="button book_button"><a href="#">Voir Plus<span /><span /><span /></a></div>
                    <div className="offertm_reviews">
                      <div className="offertm_reviews_content">
                        <div className="offertm_reviews_title">very good</div>
                        <div className="offertm_reviews_subtitle">100 reviews</div>
                      </div>
                      <div className="offertm_reviews_rating text-center">8.1</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


              </div>
            );
          })
        ) : (
          <h3>Loading...</h3>
        )}
      </React.Fragment>
 
             
         
         
          </div>
        </div>
      </div>
    </div>		
  </div> */}
</div>


{/* Single Listing */}
<div className="container">
  <div className="row">
    <div className="col-lg-12">
      <div className="single_listing">
        {/* Hotel Info */}
        <div className="hotel_info">
          {/* Title */}
          <div className="hotel_title_container d-flex flex-lg-row flex-column">
            <div className="hotel_title_content">
              <h1 className="hotel_title">          
              
              
         
        {!isLoading ? (
            this.state.users.data[0].name  
        ) : (
           'Loading...' 
        )}
        </h1>
              <div className="rating_r rating_r_4 hotel_rating">
                <i />
                <i />
                <i />
                <i />
                <i />
              </div>
              <div className="opentimenow">
              {!isLoading ? [
          console.log(users.date[d.getUTCDay()]),
          console.log(danow),
          
          
          users.date.length > 0 ? 
          danow > users.date[d.getUTCDay()].start && danow < users.date[d.getUTCDay()].end ?  'Ouvert maintenant' :  'Fermé maintenant' 
          : ''

    
          ] : (
          'Loading...' 
          )}
              </div>
              <div className="hotel_location"> {!isLoading ? (
               
            this.state.users.data[0].mail 
        ) : (
          <h3>Loading...</h3>
        )}</div>
            </div>

             

            <div className="hotel_title_button ml-lg-auto text-lg-right">
              <div className="button book_button trans_200 btn-single"><a href={`tel:${ !isLoading ? (
               
                this.state.users.data[0].mone  
           ) :''}`}>Appeler <span /><span /><span /></a></div>
              <div className="hotel_map_link_container">
                <div className="hotel_map_link"><a target="_blanc" href=
                {!isLoading ?  (
                  console.log(this.state.users),
                this.state.users.data[0].mapiframe  == ""  || this.state.users.data[0].mapiframe == "NULL" ? '' : this.state.users.data[0].mapiframe
            ) : (
               '#' 
            )}
                > Voir l'emplacement sur Map </a></div>
              </div>
            </div>
          </div>
          {/* Listing Image */}

          {!isLoading ? (
// this.state.users.data[0].img  =="" || this.state.users.data[0].img == null ? alert('epic') :
            images[0]={
               
              original : this.state.users.data[0].img  =="" || this.state.users.data[0].img == null ? 'https://i.stack.imgur.com/y9DpT.jpg' : urlapi+"/images/news/"+this.state.users.data[0].img,
      thumbnail : this.state.users.data[0].img  =="" || this.state.users.data[0].img == null ? 'https://i.stack.imgur.com/y9DpT.jpg' : urlapi+"/images/news/"+this.state.users.data[0].img,
            } ,
             this.state.img.map((img,index) => {
    {console.log('im'+ img.img)}

    images[index+1]={
      original :  urlapi+"/images/"+img.img,
      thumbnail :  urlapi+"/images/"+img.img,
    }
  }) ,
  console.log('iiiiiiiiiiiiiiiii'+'') 

        ) : (
           'Loading...' 
        )}


          <div className="row mt-4 pt-4">
  <div className="col-lg-6" >

  {!isLoading ? (
              
    <ImageGallery items={images} />

        ) : (
           'Loading...' 
        )}  

</div>


{!isLoading ? (
               
         
 users.product.length > 0 ? (
 <div className="col-lg-6 pct">
   {}
   <a href={"/product/"+users.product[0].id}>
   <div className="price-product">
     <span>  {users.product[0].price_h} DA</span>
     <span>  {users.product[0].price_l} DA </span>
     
   </div>
   
   <div className="discount-price">
   {'- ' +  parseInt(calculatePercentage(users.product[0].price_l , users.product[0].price_h)) + '%'}
   </div>
   <div className="img-product">
     <img src={urlapi+'/images/product/'+users.product[0].img} />
   </div>
   <div className="title-product">
     {users.product[0].title}
   </div>
   </a>
 </div>) : ('')

) : (
  'Loading...' 
)}

</div>


          <div className="row mt-5 hotel_image">

          <div className="col-lg-6 mt-lg-0  mt-sm-5 mt-5">
{/* Hotel Info Text */}
<div className="info-items">

{!isLoading ?   [
               
this.state.users.data[0].adr  == "" ? '' : <p> <i class="fas fa-map-marked-alt"></i>  
{this.state.users.data[0].adr }</p>,

this.state.users.data[0].mone  == "" ? '' : <p>  <i class="fas fa-mobile-alt"></i>  
{this.state.users.data[0].mone }</p>,

this.state.users.data[0].eone  == "" ? '' : <p>  <i class="far fa-envelope"></i>  
{this.state.users.data[0].eone }</p>,

this.state.users.data[0].tel  == "" || this.state.users.data[0].tel == null ? '' : <p>  <i class="fas fa-phone-volume"></i>  
{this.state.users.data[0].tel }</p>,

this.state.users.data[0].fixe  == "" ? '' : <p>  <i class="fas fa-fax"></i>  
{this.state.users.data[0].fixe }</p>,

this.state.users.data[0].web  == "" || this.state.users.data[0].web == null ? '' : <p> <i class="fas fa-desktop"></i>   <a target="_blanc" href={this.state.users.data[0].web }>{this.state.users.data[0].web }</a>
 </p>,

this.state.users.data[0].lienfb  == "" || this.state.users.data[0].lienfb == null ? '' : <p><i class="fab fa-facebook-square"></i>    <a target="_blanc" href={this.state.users.data[0].lienfb }>www.facebook.com</a>
 </p>,

         ] : (
           'Loading...' 
        )}


  {/* <p> <i class="fas fa-map-marked-alt"></i> {!isLoading ?  (
              console.log(this.state.users),
            this.state.users.data[0].adr  
        ) : (
           'Loading...' 
        )}</p>

<p> <i class="fas fa-mobile-alt"></i> {!isLoading ? (
              console.log(this.state.users),
            this.state.users.data[0].mone  
        ) : (
           'Loading...' 
        )}</p>
        <p> <i class="far fa-envelope"></i>  {!isLoading ? (
              console.log(this.state.users),
            this.state.users.data[0].eone  
        ) : (
           'Loading...' 
        )}</p>

<p> <i class="fas fa-phone-volume"></i> {!isLoading ?  (
              console.log(this.state.users),
            this.state.users.data[0].tel   
        ) : (
           'Loading...' 
        )}</p>

<p> <i class="fas fa-fax"></i> {!isLoading ?  (
              console.log(this.state.users),
            this.state.users.data[0].fixe   
        ) : (
           'Loading...' 
        )}</p> */}
</div>
<div className="hotel_info_text">
             
          </div>
          </div>


            <div className="col">
          <p className="t-items">Présentation</p>
          <p className="resum"> 

          {!isLoading ? (
          console.log(this.state.users),
          this.state.users.data[0].resum 
          ) : (
          'Loading...' 
          )}
          </p>

          {/* {!isLoading ? (
             <img src={"https://digibled.com/api_test/store/admin/admin/images/news/"+this.state.users.data[0].img} alt="" />
        ) : (
           'Loading...' 
        )} */}
        
            
            {/* <div className="hotel_review_container d-flex flex-column align-items-center justify-content-center">
              <div className="hotel_review">
                <div className="hotel_review_content">
                  <div className="hotel_review_title">very good</div>
                  <div className="hotel_review_subtitle">100 reviews</div>
                </div>
                <div className="hotel_review_rating text-center">8.1</div>
              </div>
            </div> */}
            </div>
          </div>

 

          {/* Hotel Gallery */}
          <div className="row hotel_gallery mt-5 ">
             
            <div className=" col-lg-7">
              <p className="t-items"> Services et prestations</p>
              <div className="prestation-items">
                
                {!isLoading ? (
              
              this.state.users.data[0].prestation =="" || this.state.users.data[0].prestation == null ? 'Il n\'y a pas de données' :

              (console.log(this.state.users),
              prestation = this.state.users.data[0].prestation.split(','),
              console.log(prestation),
              
              prestation.map((data,i) =>{
                return (
                  <p><i class="fas fa-check"></i>  {data }</p>
                )
              }))
        ) : (
           'Loading...' 
        )}
              </div>

              <p className="t-items">Type d'activité    </p>
              <div className="prestation-items">
                
                {!isLoading ?  [
              
              this.state.users.data[0].services =="" || this.state.users.data[0].services == null ? 'Il n\'y a pas de données' :

              (console.log(this.state.users),
              prestation = this.state.users.data[0].services.split(','),
              console.log(prestation),
              
              prestation.map((data,i) =>{
                return (
                  <p><i class="fas fa-check"></i>  {data }</p>
                )
              }))
            ] : (
           'Loading...' 
        )}
              </div>

              

              <p className="t-items">Proximité    </p>
              <div className="prestation-items">
                {!isLoading ? (
           (this.state.users.data[0].air == "" || this.state.users.data[0].tram || this.state.users.data[0].metro || this.state.users.data[0].taxi || this.state.users.data[0].car == "") ? 'Il n\'y a pas de données' : ''
          ) : (
           '' 
          )}

                {!isLoading ?  [
              console.log(this.state.users),
              // prestation = this.state.users.data[0].services.split(','),
              console.log(prestation),

              this.state.users.data[0].air == "" ? '' : <p><i class="fas fa-plane-departure"></i>  Aéroport {this.state.users.data[0].air  }</p>,

              this.state.users.data[0].tram  == "" ? '' : <p><i class="fas fa-tram"></i>  Tramway  {this.state.users.data[0].tram   }</p>,
              
              this.state.users.data[0].metro  == "" ? '' : <p> <i class="fas fa-subway"></i> Metro   {this.state.users.data[0].metro   }</p>,
              
              this.state.users.data[0].taxi  == "" ? '' : <p> <i class="fas fa-taxi"></i> Taxi  {this.state.users.data[0].taxi   }</p>,
              
              this.state.users.data[0].car  == "" ? '' : <p> <i class="fas fa-bus"></i> Bus  {this.state.users.data[0].car   }</p>
              
              // prestation.map((data,i) =>{
              //   return (
              //     <p><i class="fas fa-check"></i>  {data }</p>
              //   )
              // })
         ] : (
           'Loading...' 
        )}
              </div>

              <p className="t-items">Moyen de paiement </p>
              <div className="prestation-items">
                
                {!isLoading ?  [
              
              this.state.users.data[0].payment =="" || this.state.users.data[0].payment == null ? 'Il n\'y a pas de données' :

              (console.log(this.state.users),
              prestation = this.state.users.data[0].payment.split(','),
              console.log(prestation),
              
              prestation.map((data,i) =>{
                return (
                  <p> <i class="fas fa-money-bill-wave"></i>  {data }</p>
                )
              }))
         ] : (
           'Loading...' 
        )}
              </div>


              <p className="t-items"> Contact    </p>
              <div className=" contact-items">
                
                {!isLoading ?  [
              console.log(this.state.users),
              // prestation = this.state.users.data[0].services.split(','),
              console.log(prestation),

                <p> <i class="fas fa-mobile-alt"></i>  Mobile :   {this.state.users.data[0].mone}  
                   {(this.state.users.data[0].mtwo  == "" || this.state.users.data[0].mtwo == null ? '' : ' / ' + this.state.users.data[0].mtwo )}
                   {(this.state.users.data[0].mthree   == "" || this.state.users.data[0].mthree == null ? '' : ' / ' + this.state.users.data[0].mthree)}     </p>,
              
              <p><i class="far fa-envelope-open"></i>    Email  :   {this.state.users.data[0].eone }  
                   {(this.state.users.data[0].etwo   == "" || this.state.users.data[0].etwo == null ? '' : ' / ' + this.state.users.data[0].etwo  )} {(this.state.users.data[0].ethree    == "" || this.state.users.data[0].ethree == null ? '' : ' / ' + this.state.users.data[0].ethree )}     </p>,

this.state.users.data[0].tel  == "" || this.state.users.data[0].tel == null ? '' : <p>   <i class="fas fa-phone-volume"></i>   Tél :
{this.state.users.data[0].tel }</p>,

this.state.users.data[0].fixe  == "" ? '' : <p>    <i class="fas fa-fax"></i>  Fixe :
{this.state.users.data[0].fixe }</p>,

this.state.users.data[0].web  == "" || this.state.users.data[0].web == null ? '' : <p>    <i class="fas fa-desktop"></i> Site web :  <a target="_blanc" href={this.state.users.data[0].web }>{this.state.users.data[0].web }</a>
 </p>,

this.state.users.data[0].lienfb  == "" || this.state.users.data[0].lienfb == null ? '' : <p>     <i class="fab fa-facebook-square"></i>  Facebook :  <a target="_blanc" href={this.state.users.data[0].lienfb }>www.facebook.com</a>
 </p>,

this.state.users.data[0].wilaya   == "" ? '' : <p>     <i class="far fa-flag"></i>  Wilaya : 
{this.state.users.data[0].wilaya  }</p>,

this.state.users.data[0].commun    == "" ? '' : <p>  <i class="fas fa-map-pin"></i>    Commune : 
{this.state.users.data[0].commun   }</p>,

 

               
              
              // prestation.map((data,i) =>{
              //   return (
              //     <p><i class="fas fa-check"></i>  {data }</p>
              //   )
              // })
         ] : (
           'Loading...' 
        )}
              </div>
              

              </div>
            <div className=" col-lg-5 mt-4">
              <div className="map-item"> <a target="_blanc" href={!isLoading ?  (
                  console.log(this.state.users),
                this.state.users.data[0].mapiframe  == ""  || this.state.users.data[0].mapiframe == "NULL" ? '' : this.state.users.data[0].mapiframe
            ) : (
               '#' 
            )}> <img src={'/images/mapdefault.jpg'} ></img></a></div>
              <div className="autre-items">
                
              <p className="t-items">Informations pratiques    </p>
              {!isLoading ?   [
               
               this.state.users.data[0].lieup  == "" || this.state.users.data[0].lieup == null ? '' : <p> <span>Haut-lieu à proximité </span><br/> <i class="fas fa-map-marked-alt"></i>  
               {this.state.users.data[0].lieup }</p>,
               
               this.state.users.data[0].annexe   == "" || this.state.users.data[0].annexe == null ? '' : <p> <span>Etablissements secondaires / Annexe</span> <br/> <i class="fas fa-house-damage"></i>    
               {this.state.users.data[0].annexe  }</p>,

              this.state.users.data[0].rsocial    == "" || this.state.users.data[0].rsocial == null ? '' : <p> <span>Raison social	</span> <br/><i class="far fa-address-card"></i>     
              {this.state.users.data[0].rsocial   }</p>,

              this.state.users.data[0].codep    == "" || this.state.users.data[0].codep == null ? '' : <p> <span>Code postal 		</span> <br/><i class="fas fa-map-signs"></i>      
              {this.state.users.data[0].codep   }</p>,

              this.state.users.data[0].jurid    == "" || this.state.users.data[0].jurid == null ? '' : <p> <span> Forme juridique </span> <br/><i class="far fa-building"></i>      
              {this.state.users.data[0].jurid   }</p>,
               
               this.state.users.data[0].wprktime   == "" || this.state.users.data[0].wprktime == null ? '' : <p> <span>Horaires </span><br/> <i class="far fa-clock"></i>    
               {this.state.users.data[0].wprktime  }</p>,
               
               this.state.users.data[0].dirig  == "" || this.state.users.data[0].dirig == null ? '' : <p> <span>Décideur/Dirigeants </span><br/>  <i class="fas fa-user-alt"></i>   
               {this.state.users.data[0].dirig }</p>,
               
               this.state.users.data[0].pmax == "" || this.state.users.data[0].pmax == null || this.state.users.data[0].pmin  == "" || this.state.users.data[0].pmin == null ? '' : <p> <span>Budget et prix </span> <br/><i class="far fa-money-bill-alt"></i> Prix élevé :  {this.state.users.data[0].pmax } <br/><i class="far fa-money-bill-alt"></i> Prix bas :  {this.state.users.data[0].pmin } </p>,
               
                
               
                        ] : (
                          'Loading...' 
                       )}
              </div>
              <div className="opentime mt-5 ">
              <table>
  
  <tbody>

  {!isLoading ? (
          console.log(this.state.users.data[0].date),

          
          
          users.date.map((opentime)=> {

            const { id,day,start,end} = opentime;

          return( start == null || end == null ? 

             <tr>
      <td data-label="Jour"> {day} </td>
            <td data-label="">Fermé</td>
      
    </tr>
   : <tr>
   <td data-label="Jour"> {day} </td>
         <td data-label="">{start + ' - ' + end }</td>
   
 </tr> ) 
          }) 
    
          ) : (
          'Loading...' 
          )}

   
  </tbody>
</table>
              </div>

              </div>
             
            <div className="hotel_slider_container">

              

            {/* <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        {!isLoading ? (
          users.img.map(user => {
            const { id,resum,mail, img ,adr,tel} = user;
            return (
              <div key={id}>
                
                {console.log(this.state.users.img[0].img)}
              {img}

              </div>
            );
          })
        ) : (
          <h3>Loading...</h3>
        )}
      </React.Fragment> */}



 



       
              {/* Hotel Slider Nav - Prev */}
               
              {/* Hotel Slider Nav - Next */}
               
            </div>
          </div>

          {!isLoading ? (
      
      
      this.state.users.product.length && (
        <div className="testimonials">
        <div className="test_border" />
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h2 className="section_title">Produits</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Testimonials Slider */}
              <div className="test_slider_container">
              <OwlCarousel {...optionstesmtwo}   className="owl-carousel owl-theme  test_slider" margin={8} >
              {/* <div className="owl-carousel owl-theme hotel_slider"> */}
                {/* Hotel Gallery Slider Item */}

                {users.product.map(user => {
            const { id,resum,mail, img , title,text,adr,tel,price_h,price_l} = user;
            return (
                 
                <div className="">
                  <a href={"/product/"+id}>
                <div className="test_item">
                  <div className="test_image"><img src={urlapi+"/images/product/"+img} alt="" /></div>
                  {/* <div className="test_icon"><img src="images/kayak.png" alt="" /></div> */}
                  <div className="test_content_container">
                    <div className="test_content">
                      <div className="test_item_info">
                        <div className="test_name">{price_h} DA</div>
                        <div className="test_date" style={{textDecoration:'line-through'}}>{price_l} DA</div>
                      </div>
                       <div className="test_quote_title">{title}</div>
            <p className="test_quote_text">{parse(text.replace(/<\/?[^>]+(>|$)/g, "").toString()).slice(0,280) + "....."}</p>
            {console.log(text)}
                    </div>
                  </div>
                </div>
                </a>
              </div>
            );
          })}
                
              
              {/* </div> */}
              </OwlCarousel>
            {/* Testimonials Slider Nav - Prev */}
            
            {/* Testimonials Slider Nav - Next */}
            
          </div>
        </div>
      </div>
    </div>
  </div>

      )) : (
         'Loading...' 
      )}


           
          {/* Hotel Info Tags */}
          <div className="hotel_info_tags">
            <ul className="hotel_icons_list">
              <li className="hotel_icons_item"><img src="/images/post.png" alt="" /></li>
              <li className="hotel_icons_item"><img src="/images/compass.png" alt="" /></li>
              <li className="hotel_icons_item"><img src="/images/bicycle.png" alt="" /></li>
              <li className="hotel_icons_item"><img src="/images/sailboat.png" alt="" /></li>
            </ul>
          </div>
        </div>
        {/* Rooms */}
        <div className="mt-5 pt-5 rooms">
          {/* Room */}


                   
          <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        {!isLoading ? (
          users.random.map(user => {
            const { id,resum,mail, img , name,adr,tel, user_id} = user;
            return (
               
                 

                 <div key={id} className="room">
             
             <div className="row">
               <div className="col-lg-2">
                 <div className="room_image"><img src={ img !=null ? urlapi+"/images/news/"+ img : 'https://i.stack.imgur.com/y9DpT.jpg' } alt=" " /></div>
               </div>
               <div className="col-lg-7">
                 <div className="room_content">
                   <div className="room_title"> {name } </div>
                   <div className="room_price"> {tel} </div>
                   <div className="room_text"> {resum.slice(0,80) + "....."} </div>
                   {/* <div className="room_extra">Breakfast $7.10</div> */}
                 </div>
               </div>
               <div className="col-lg-3 text-lg-right">
                 <div className="room_button">
                   <div className="button book_button trans_200"><a href={"/page/"+ user_id}>Voir<span /><span /><span /></a></div>
                 </div>
               </div>
             </div>	
           </div>


               
            );
          })
        ) : (
          <h3>Loading...</h3>
        )}
      </React.Fragment>

         
          {/* Room */}
           

        </div>

        <div className="comment-items container">
          <form onSubmit={this.onsub}>
            <div className="row">
              <h1> Note et commentaire </h1>
              <div className="col-12">
            <div className="rate">
            <input type="radio" id="star5" onChange={this.sub} name="rate"  value="5" defaultChecked checked={this.state.rate == 5} />
            <label for="star5" title="text">5 stars</label>
            <input type="radio" id="star4" onChange={this.sub} name="rate" value="4" checked={this.state.rate == 4} />
            <label for="star4" title="text">4 stars</label>
            <input type="radio" id="star3" onChange={this.sub} name="rate" value="3" checked={this.state.rate == 3} />
            <label for="star3" title="text">3 stars</label>
            <input type="radio" id="star2" onChange={this.sub} name="rate" value="2" checked={this.state.rate == 2} />
            <label for="star2" title="text">2 stars</label>
            <input type="radio" id="star1" onChange={this.sub} name="rate" value="1" checked={this.state.rate == 1} />
            <label for="star1" title="text">1 star</label>
            </div>
            </div>
            <div className="input-items col-sm-12 col-md-8 col-lg-8">
              <input type="text" name="email" onChange={this.sub}  value={this.state.email} placeholder="Votre Email" />
              <textarea name="message" onChange={this.sub} value={this.state.message} placeholder="Commentaire ..." > </textarea>
            </div>
            </div>
            <button type="submit" id="form_submit_button" className="form_submit_button button trans_200 comment-btn">Envoyer</button>
            </form>
            
            {!isLoading ? (

 users.comment.map((data,i) =>{
  return (

            
          
            <div className="rate-comment row">
              <div className="rate-section col-sm-12 col-md-3">
              <div className="rate">
            <input type="radio" id="star5"   value="5"  defaultChecked={data.rate == "5" ? 'checked' : ''}  />
            <label for="star5" title="text">5 stars</label>
            <input type="radio" id="star4"  value="4"    defaultChecked={data.rate == "4" ? 'checked' : ''} />
            <label for="star4" title="text">4 stars</label>
            <input type="radio" id="star3"  value="3"   defaultChecked={data.rate == "3" ? 'checked' : ''} />
            <label for="star3" title="text">3 stars</label>
            <input type="radio" id="star2"  value="2"   defaultChecked={data.rate == "2" ? 'checked' : ''} />
            <label for="star2" title="text">2 stars</label>
            <input type="radio" id="star1"  value="1"   defaultChecked={data.rate == "1" ? 'checked' : ''} />
            <label for="star1" title="text">1 star</label>
            </div>
              </div>
              <div className="rate-text col-sm-12 col-md-8">
                <p>Email : {data.email} </p>
                <p>{data.comment}</p>
              </div>
            </div>
 )
})
            ) : (
              '' 
             )}
        </div>
        {/* Reviews */}
        {/* <div className="reviews">
          <div className="reviews_title">reviews</div>
          <div className="reviews_container"> */}
            {/* Review */}
            {/* <div className="review">
              <div className="row">
                <div className="col-lg-1">
                  <div className="review_image">
                    <img src="/images/review_1.jpg" alt="https://unsplash.com/@saaout" />
                  </div>
                </div>
                <div className="col-lg-11">
                  <div className="review_content">
                    <div className="review_title_container">
                      <div className="review_title">"We loved the services"</div>
                      <div className="review_rating">9.5</div>
                    </div>
                    <div className="review_text">
                      <p>Tetur adipiscing elit. Nullam eu convallis tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis vulputate eros, iaculis consequat nisl. Nunc et suscipit urna. Integer elementum orci eu vehicula pretium. Donec bibendum tristique condimentum.</p>
                    </div>
                    <div className="review_name">Christinne Smith</div>
                    <div className="review_date">12 November 2017</div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Review */}
            {/* <div className="review">
              <div className="row">
                <div className="col-lg-1">
                  <div className="review_image">
                    <img src="/images/review_2.jpg" alt="Image by Andrew Robles" />
                  </div>
                </div>
                <div className="col-lg-11">
                  <div className="review_content">
                    <div className="review_title_container">
                      <div className="review_title">"Nice staff and great location"</div>
                      <div className="review_rating">9.5</div>
                    </div>
                    <div className="review_text">
                      <p>Tetur adipiscing elit. Nullam eu convallis tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis vulputate eros, iaculis consequat nisl. Nunc et suscipit urna. Integer elementum orci eu vehicula pretium. Donec bibendum tristique condimentum.</p>
                    </div>
                    <div className="review_name">Christinne Smith</div>
                    <div className="review_date">12 November 2017</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
         
      </div>
    </div>
  </div>
</div>


</div>
    
  );
}
}

export default Page;
