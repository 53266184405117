import React ,{Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './styles/main_styles.css';
import './styles/responsive.css';
import { Link } from 'react-router-dom';

const options = {
  margin: 30,
  responsiveClass: true,
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  navText: ['<div class="home_slider_nav home_slider_prev"> <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"> <defs> <linearGradient id="home_grad_prev"> <stop offset="0%" stop-color="#fa9e1b"></stop> <stop offset="100%" stop-color="#8d4fff"></stop> </linearGradient> </defs> <path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path> <polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219 11.042,18.219 "></polygon> </svg> </div>', '<div class="home_slider_nav home_slider_next"> <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"> <defs> <linearGradient id="home_grad_next"> <stop offset="0%" stop-color="#fa9e1b"></stop> <stop offset="100%" stop-color="#8d4fff"></stop> </linearGradient> </defs> <path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path> <polygon class="nav_arrow" fill="#F3F6F9" points="13.044,11.551 11.71,12.885 15.714,16.888 11.71,20.891 13.044,22.224 18.379,16.888 17.048,15.554 17.046,15.554 "></polygon> </svg> </div>'],
  smartSpeed: 1000,
  items: 1,
  height: 200,
};

const optionscta = {
  margin: 30,
  responsiveClass: true,
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  navText: ['<div class="cta_slider_nav cta_slider_prev"><svg version="1.1" id="Layer_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="cta_grad_prev"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219  11.042,18.219 "></polygon></svg></div>', '<div class="cta_slider_nav cta_slider_next"> <svg version="1.1" id="Layer_5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"> <defs> <linearGradient id="cta_grad_next"> <stop offset="0%" stop-color="#fa9e1b"></stop> <stop offset="100%" stop-color="#8d4fff"></stop> </linearGradient> </defs> <path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path> <polygon class="nav_arrow" fill="#F3F6F9" points="13.044,11.551 11.71,12.885 15.714,16.888 11.71,20.891 13.044,22.224 18.379,16.888 17.048,15.554 17.046,15.554 "></polygon> </svg> </div>'],
  smartSpeed: 1000,
  items: 3,
  height: 200,
  responsive:
				{
					0:{items:1},
					480:{items:1},
					768:{items:2},
					992:{items:3}
				}
};


const optionstesm = {
  loop:true,
				nav:true,
				dots:false,
				smartSpeed:1200,
        margin:30,
        navText: ['<div class="test_slider_nav test_slider_prev"><svg version="1.1" id="Layer_6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="test_grad_prev"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="15.044,22.222 16.377,20.888 12.374,16.885 16.377,12.882 15.044,11.55 9.708,16.885 11.04,18.219  11.042,18.219 "></polygon></svg></div>', '<div class="test_slider_nav test_slider_next"><svg version="1.1" id="Layer_7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px" height="33px" viewBox="0 0 28 33" enable-background="new 0 0 28 33" xml:space="preserve"><defs><linearGradient id="test_grad_next"><stop offset="0%" stop-color="#fa9e1b"></stop><stop offset="100%" stop-color="#8d4fff"></stop></linearGradient></defs><path class="nav_path" fill="#F3F6F9" d="M19,0H9C4.029,0,0,4.029,0,9v15c0,4.971,4.029,9,9,9h10c4.97,0,9-4.029,9-9V9C28,4.029,23.97,0,19,0z M26,23.091C26,27.459,22.545,31,18.285,31H9.714C5.454,31,2,27.459,2,23.091V9.909C2,5.541,5.454,2,9.714,2h8.571 C22.545,2,26,5.541,26,9.909V23.091z"></path><polygon class="nav_arrow" fill="#F3F6F9" points="13.044,11.551 11.71,12.885 15.714,16.888 11.71,20.891 13.044,22.224 18.379,16.888 17.048,15.554  17.046,15.554 "></polygon></svg></div>'],
				responsive:
				{
					0:{items:1},
					480:{items:1},
					768:{items:2},
					992:{items:3}
				}
};


const imgone =['2.jpg','29.jpg','46.jpg','64.jpg','68.jpg','40.jpg','69.jpg','58.jpg','59.jpg','53.jpg','80.jpg'];
const imgtwo =['3.jpg','8.jpg','18.jpg','62.jpg','66.jpg','42.jpg','60.jpg','48.jpg','51.jpg','54.jpg'];
const imgth = ['20.jpg','22.jpg','29.jpg','30.jpg','39.jpg','44.jpg','45.jpg','56.jpg'];


// const urlapi = "http://192.168.1.10/annuaire_api";
const urlapi = "https://alpaja.com/annuaire_api";
// const urlapi = "http://192.168.1.36/annuaire_api";
// const urlapi = "http://192.168.43.14/annuaire_api";




class Home extends Component {
   

  state ={
    id:'test',
    cat:'alger', 
    isLoading: true,
    users: [],
    error: null,
    img : null,
    email : '',
    message : '',
    rate : 5,
  }


  fetchUsers() {
    const id = this.props.match.params.id;
    console.log(id);
    fetch(`${urlapi}/api/itemshome`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          users: data,
          isLoading: false,
          img: data.img
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.fetchUsers();
    // this.Button();
  }


   render(){
    const { isLoading, users, error } = this.state;
   

  return (
     <div>

       
  <div className="home_slider_annuaire">
  <OwlCarousel {...options}  className="owl-theme"  margin={8} >  
           <div>
           <div className="home_slider_content text-center">

            <div className="home_slider_content_inner" data-animation-in="flipInX" data-animation-out="animate-out fadeOut">
            <h1>Découvrez</h1>
            <h1>L'algérie</h1>
            <div className="button home_slider_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>
            
            </div>
            <img className="img" src= {'images/sliderandom/'+imgone[Math.floor(Math.random()*imgone.length)]}/>
            </div>  
            </div>

            <div>
           <div className="home_slider_content text-center">

            <div className="home_slider_content_inner" data-animation-in="flipInX" data-animation-out="animate-out fadeOut">
            <h1>Découvrez</h1>
            <h1>L'algérie</h1>
            <div className="button home_slider_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>

            </div>
            <img className="img" src= {'images/sliderandom/'+imgtwo[Math.floor(Math.random()*imgtwo.length)]}/>
            </div>  
            </div>


            <div>
           <div className="home_slider_content text-center">

            <div className="home_slider_content_inner" data-animation-in="flipInX" data-animation-out="animate-out fadeOut">
            <h1>Découvrez</h1>
            <h1>L'algérie</h1>
            <div className="button home_slider_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>

            </div>
            <img className="img" src= {'images/sliderandom/'+imgth[Math.floor(Math.random()*imgth.length)]}/>
            </div>  
            </div>

         
      </OwlCarousel>
      </div>


      <div className="search">
  <div className="container fill_height">
    <div className="row fill_height">
      <div className="col fill_height">
        <div className="search_tabs_container">
          <div className="search_tabs d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-lg-between justify-content-start">
            <div className="search_tab d-flex flex-row align-items-center justify-content-lg-center justify-content-start"><i className="fa fa-cutlery" aria-hidden="true"></i><span>Restaurants</span></div>
            <div className="search_tab d-flex flex-row align-items-center justify-content-lg-center justify-content-start"><i className="fa fa-shopping-bag" aria-hidden="true"></i>Magasins</div>
            <div className="search_tab d-flex flex-row align-items-center justify-content-lg-center justify-content-start"><i className="fa fa-building-o" aria-hidden="true"></i>Société</div>
            <div className="search_tab d-flex flex-row align-items-center justify-content-lg-center justify-content-start"><i className="fa fa-shopping-basket" aria-hidden="true"></i>Boutiques</div>
            <div className="search_tab d-flex flex-row align-items-center justify-content-lg-center justify-content-start"><i className="fa fa-female" aria-hidden="true"></i>Salon</div>
            <div className="search_tab d-flex flex-row align-items-center justify-content-lg-center justify-content-start"><i className="fa fa-map-marker" aria-hidden="true"></i>Lieux</div>
          </div>
        </div>
        <div className="search_panel active">
          <form id="search_form_1" className="search_panel_content d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-lg-between justify-content-start">


          <div className="item-search">

          {/* <div className="search_item">
    <div>destination</div>
    <input type="text" className="destination search_input" required="required" />
  </div> */}
  {/* <div className="search_item">
    <div>adults</div>
    <select name="adults" id="adults_1" className="dropdown_item_select search_input">
      <option>01</option>
      <option>02</option>
      <option>03</option>
    </select>
  </div>
  <div className="search_item">
    <div>children</div>
    <select name="children" id="children_1" className="dropdown_item_select search_input">
      <option>0</option>
      <option>02</option>
      <option>03</option>
    </select>
  </div> */}
  
  <div className="search_item"><div>De quoi avez-vous besoin ?</div><input onChange={(e)=>{this.setState({id : e.target.value})}} type="text" placeholder="Quoi, Qui : hôtel avec piscine, Restaurant, ..." className="destination search_input" required /></div>
  <div className="search_item"><div>Wilaya</div><select onChange={(e)=>{this.setState({ cat : e.target.value})}} name="children" id="children_1" className="dropdown_item_select search_input"> 
  <option value="all" selected>Wilayas</option>
  <option> adrar </option>     <option>chlef</option>       <option>laghouat</option>       <option>oum bouaghi</option>      <option>batna</option>       <option>bejaia</option>        <option>biskra</option>    <option>bechar</option>    <option>blida</option>      <option>bouira</option>  <option> tamanrasset</option>  <option>tebessa</option> <option>tlemcen</option>  <option>tiaret</option>  <option> tizi ouzou</option>  <option>alger</option>  <option>djelfa</option>
   <option>jijel</option>
   <option>setif</option>

   <option>saida</option>
   <option>skikda</option>
   <option>sidi bel abbes</option>
   <option>annaba</option>
   <option>guelma</option>
   <option>constantine</option>
   <option>medea</option>
   <option>mostaganem</option>
   <option>msila</option>
   <option>mascara</option>
   <option>ouargla</option>
   <option>oran</option>
   <option>el baydh</option>

   <option>illizi</option>
   <option>bordj bou arreridj</option>
   <option>boumerdes</option>
   <option>el taref</option>
   <option>tindouf</option>
   <option>tissemsilt</option>
   <option>el oued</option>
   <option>khenchla</option>

   <option>souk ahrass</option>
   <option>tipaza</option>
   <option>mila</option>
   <option>aïn defla</option>
   <option>naama</option>
   <option>ain temouchent</option>
  <option>ghardaia</option>
  <option>relizane</option>
  </select></div>
</div>

{console.log(this.state.cat)}
            
      <button className="button search_button"><Link  to= {'/test?filter='+this.state.id+'&cat='+this.state.cat} >Recherche<span /><span /><span /></Link></button>

          </form>
        </div>


      </div>
    </div>
  </div>
</div>


      <div>
  {/* Intro */}
  <div className="intro">
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="intro_title text-center">Annuaire D'algérie</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="intro_text text-center">
            <p>Ce site vous présente les différentes structures, lieux et institutions en Algérie à travers chaque Wilaya et il vous est facile de trouver et de connaître chaque Wilaya et région dans ses différents départements</p>
          </div>
        </div>
      </div>
      <div className="row intro_items">
        {/* Intro Item */}
        <div className="col-lg-4 intro_col">
          <div className="intro_item">
            <div className="intro_item_overlay" />
            {/* Image by https://unsplash.com/@dnevozhai */}
            <div className="intro_item_background" style={{backgroundImage: 'url(images/alger.jpg)'}} />
            <div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
              <div className="intro_date">Recherche dans</div>
              <div className="button intro_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>
              <div className="intro_center text-center">
                <h1>Alger</h1>
                <div className="intro_price">Wilaya D'alger</div>
                <div className="rating rating_4">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Intro Item */}
        <div className="col-lg-4 intro_col">
          <div className="intro_item">
            <div className="intro_item_overlay" />
            {/* Image by https://unsplash.com/@hellolightbulb */}
            <div className="intro_item_background" style={{backgroundImage: 'url(images/oran.jpg)'}} />
            <div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
              <div className="intro_date">Recherche dans</div>
              <div className="button intro_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>
              <div className="intro_center text-center">
                <h1>Oran</h1>
                <div className="intro_price">Wilaya d'oran</div>
                <div className="rating rating_4">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Intro Item */}
        <div className="col-lg-4 intro_col">
          <div className="intro_item">
            <div className="intro_item_overlay" />
            {/* Image by https://unsplash.com/@willianjusten */}
            <div className="intro_item_background" style={{backgroundImage: 'url(images/skikda.jpg)'}} />
            <div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
              <div className="intro_date">Recherche dans</div>
              <div className="button intro_button"><div className="button_bcg"></div><a href="/test">Découvrir<span></span><span></span><span></span></a></div>
              <div className="intro_center text-center">
                <h1>Skikda</h1>
                <div className="intro_price">Wilaya de skikda</div>
                <div className="rating rating_4">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* CTA */}

  {!isLoading ? (
      
      
      this.state.users.product.length && (

  <div className="cta">
    {/* Image by https://unsplash.com/@thanni */}
    <div className="cta_background" style={{backgroundImage: 'url(images/cta.jpg)'}} />
    <div className="container">
      <div className="row">
        <div className="col">
          {/* CTA Slider */}
          <div className="cta_slider_container">
            {/* <div className="owl-carousel owl-theme cta_slider"> */}
            <OwlCarousel {...optionscta}   className="owl-carousel owl-theme cta_slider" margin={8} >
              {/* CTA Slider Item */}
               
              {users.product.map(user => {
            const { id,name,resum,mail, img , title, user_id, text,adr,tel,price_h,price_l , wilaya , commun} = user;
            return (
               
              <div className=" cta_item text-center">
                <img style={{maxHeight :"200px" , minHeight: "200px" , objectFit:"cover" , width :'100%'}} src={urlapi+"/images/product/"+img} alt="" />
            <div className="cta_title product-t"> {title} </div>
                <div className="rating_r rating_r_4">
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                </div>
                <p className="cta_text" style={{textDecoration: "line-through",color: "#a05dd7"}}> {price_l} DA</p>
                <p className="cta_text"> {price_h} DA</p>
                <div className="button cta_button"><div className="button_bcg" /><a href={"/product/"+id}> Voir <span /><span /><span /></a></div>
              </div>


);
})}

              </OwlCarousel>
            {/* </div> */}
            {/* CTA Slider Nav - Prev */}
            
            {/* CTA Slider Nav - Next */}
          </div>
        </div>
      </div>
    </div>
  </div>

)) : (
  'Loading...' 
)}
  {/* Offers */}
  <div className="offers o-home">
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <h2 className="section_title">Lieux recommandés</h2>
        </div>
      </div>
      <div className="row offers_items">
        {/* Offers Item */}
        <div className="col-lg-6 offers_col">
          <div className="offers_item">
            <div className="row">
              <div className="col-lg-6">
                <div className="offers_image_container">
                  {/* Image by https://unsplash.com/@kensuarez */}
                  <div className="offers_image_background" style={{backgroundImage: 'url(images/ett/restaurant.png)'}} />
                  {/* <div className="offer_name"><a href="/test">Oran</a></div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="offers_content">
                  <div className="offers_price">Restauration<span></span></div>
                  <div className="rating_r rating_r_4 offers_rating">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                  </div>
                  <p className="offers_text">Découvrez les meilleurs restaurants à travers les différents états du pays avec des photos et des détails.</p>
                  <div className="offers_icons">
                    <ul className="offers_icons_list">
                      <li className="offers_icons_item"><img src="images/post.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/compass.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/bicycle.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/sailboat.png" alt="" /></li>
                    </ul>
                  </div>
                  <div className="offers_link"><a href="/test">Voir Plus</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Offers Item */}
        <div className="col-lg-6 offers_col">
          <div className="offers_item">
            <div className="row">
              <div className="col-lg-6">
                <div className="offers_image_container">
                  {/* Image by Egzon Bytyqi */}
                  <div className="offers_image_background" style={{backgroundImage: 'url(images/lux-inox-photos.jpg)'}} />
                  {/* <div className="offer_name"><a href="/test">ALger</a></div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="offers_content">
                  <div className="offers_price">Services<span></span></div>
                  <div className="rating_r rating_r_4 offers_rating">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                  </div>
                  <p className="offers_text">Découvrez les différentes activités diverses et différentes à travers tous les états du pays.</p>
                  <div className="offers_icons">
                    <ul className="offers_icons_list">
                      <li className="offers_icons_item"><img src="images/post.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/compass.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/bicycle.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/sailboat.png" alt="" /></li>
                    </ul>
                  </div>
                  <div className="offers_link"><a href="/test">Voir Plus</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Offers Item */}
        <div className="col-lg-6 offers_col">
          <div className="offers_item">
            <div className="row">
              <div className="col-lg-6">
                <div className="offers_image_container">
                  {/* Image by https://unsplash.com/@nevenkrcmarek */}
                  <div className="offers_image_background" style={{backgroundImage: 'url(images/ett/shopping.jpg)'}} />
                  {/* <div className="offer_name"><a href="/test">Oran</a></div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="offers_content">
                  <div className="offers_price">Shopping<span></span></div>
                  <div className="rating_r rating_r_4 offers_rating">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                  </div>
                  <p className="offers_text">En savoir plus sur les magasins et magasins les plus importants et les plus divers.</p>
                  <div className="offers_icons">
                    <ul className="offers_icons_list">
                      <li className="offers_icons_item"><img src="images/post.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/compass.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/bicycle.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/sailboat.png" alt="" /></li>
                    </ul>
                  </div>
                  <div className="offers_link"><a href="/test">Voir Plus</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Offers Item */}
        <div className="col-lg-6 offers_col">
          <div className="offers_item">
            <div className="row">
              <div className="col-lg-6">
                <div className="offers_image_container">
                  {/* Image by https://unsplash.com/@mantashesthaven */}
                  <div className="offers_image_background" style={{backgroundImage: 'url(images/ett/Medcin.jpg)'}} />
                  {/* <div className="offer_name"><a href="/test">Oran</a></div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="offers_content">
                  <div className="offers_price"> Santé<span></span></div>
                  <div className="rating_r rating_r_4 offers_rating">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                  </div>
                  <p className="offers_text">Découvrez tout sur la médecine et la santé dans divers domaines avec les meilleurs endroits et points.</p>
                  <div className="offers_icons">
                    <ul className="offers_icons_list">
                      <li className="offers_icons_item"><img src="images/post.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/compass.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/bicycle.png" alt="" /></li>
                      <li className="offers_icons_item"><img src="images/sailboat.png" alt="" /></li>
                    </ul>
                  </div>
                  <div className="offers_link"><a href="/test">Voir Plus</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  {/* Testimonials */}

  {!isLoading ? (
      
      
      this.state.users.product.length && (

  <div className="testimonials">
    <div className="test_border" />
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <h2 className="section_title">Annuaire Des Professionnels</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {/* Testimonials Slider */}
          <div className="test_slider_container">
          <OwlCarousel {...optionstesm}   className="owl-carousel owl-theme  test_slider" margin={8} >
            {/* <div className="owl-carousel owl-theme test_slider"> */}
               
              {/* Testimonial Item */}
              {/* <div className="">
                <div className="test_item">
                  <div className="test_image"><img src="images/test_3.jpg" alt="https://unsplash.com/@seefromthesky" /></div>
                  <div className="test_icon"><img src="images/kayak.png" alt="" /></div>
                  <div className="test_content_container">
                    <div className="test_content">
                      <div className="test_item_info">
                        <div className="test_name">Société</div>
                        <div className="test_date">Alger Centre</div>
                      </div>
                      <div className="test_quote_title">LN CONSULTING</div>
                      <p className="test_quote_text">Une entreprise spécialisée dans la conception de sites Web et d'applications mobiles.</p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Testimonial Item */}
              {users.data.map(user => {
            const { id,name,resum,mail, img , title, user_id, text,adr,tel,price_h,price_l , wilaya , commun} = user;
            return (
              
              <div className="">
                <a href={"/page/"+ user_id}>
                <div className="test_item">
                  <div className="test_image"><img src={img != null ?  urlapi+'/images/news/' + img : 'https://i.stack.imgur.com/y9DpT.jpg'} alt="" /></div>
                  <div className="test_icon"><img src="images/kayak.png" alt="" /></div>
                  <div className="test_content_container">
                    <div className="test_content">
                      <div className="test_item_info">
                        <div className="test_name"> {wilaya} </div>
                        <div className="test_date"> {commun} </div>
                      </div>
                      <div className="test_quote_title"> {name} </div>
                      <p className="test_quote_text"> {resum.slice(0,120) + "....."} </p>
                    </div>
                  </div>
                </div>
                </a>
              </div>
               );
              })}
              {/* Testimonial Item */}
              {/* <div className="">
                <div className="test_item">
                  <div className="test_image"><img src="images/test_1.jpg" alt="" /></div>
                  <div className="test_icon"><img src="images/kayak.png" alt="" /></div>
                  <div className="test_content_container">
                    <div className="test_content">
                      <div className="test_item_info">
                        <div className="test_name">Société</div>
                        <div className="test_date">Alger Centre</div>
                      </div>
                      <div className="test_quote_title">LN CONSULTING</div>
                      <p className="test_quote_text">Une entreprise spécialisée dans la conception de sites Web et d'applications mobiles.</p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Testimonial Item */}
              {/* <div className="">
                <div className="test_item">
                  <div className="test_image"><img src="images/test_3.jpg" alt="" /></div>
                  <div className="test_icon"><img src="images/kayak.png" alt="" /></div>
                  <div className="test_content_container">
                    <div className="test_content">
                      <div className="test_item_info">
                        <div className="test_name">Société</div>
                        <div className="test_date">May 24, 2017</div>
                      </div>
                      <div className="test_quote_title">LN CONSULTING</div>
                      <p className="test_quote_text">Une entreprise spécialisée dans la conception de sites Web et d'applications mobiles.</p>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            </OwlCarousel>
            {/* Testimonials Slider Nav - Prev */}
            
            {/* Testimonials Slider Nav - Next */}
            
          </div>
        </div>
      </div>
    </div>
  </div>

)) : (
  'Loading...' 
)}


{!isLoading ? (
      
      
      this.state.users.product.length && (

  <div className="trending">
    <div className="container">
      <div className="row">
        {/* <div className="col text-center">
          <h2 className="section_title">à la une</h2>
        </div> */}
      </div>
      <div className="row trending_container" >

      {users.data.map(user => {
            const { id,name,resum,mail, img , title, user_id, text,adr,tel,price_h,price_l , wilaya , commun} = user;
            return (
              
        <div className="col-lg-3 col-sm-6">
          <a href={"/page/"+ user_id}>
          <div className="trending_item clearfix">
            <div className="trending_image"><img src={img != null ?  urlapi+'/images/news/' + img : 'https://i.stack.imgur.com/y9DpT.jpg'} alt="" /></div>
            <div className="trending_content">
              <div className="trending_title"><a href={"/page/"+ user_id}> {name} </a></div>
              {/* <div className="trending_price">8600 DA</div> */}
              <div className="trending_location"> {wilaya} - {commun} </div>
            </div>
          </div>
          </a>
        </div> 
        
);
})}
      </div>
    </div>
  </div>
  
)) : (
  'Loading...' 
)}

</div>



</div>
    
  );
}
}

export default Home;
