import React ,{Component} from 'react';
import {Link , useLocation} from "react-router-dom";
import queryString from 'query-string';

import './styles/offers_styles.css';
import './styles/offers_responsive.css';

 


// const urlapi = "http://192.168.43.14/annuaire_api";
const urlapi = "https://alpaja.com/annuaire_api";
// const urlapi = "http://192.168.1.10/annuaire_api";
// const urlapi = "http://192.168.1.36/annuaire_api";


var ratelength =[];


class Annuaire extends Component {


  state = {
    isLoading: true,
    users: [],
    g: 'all',
    c:'all',
    error: null,
    get:  queryString.parse(this.props.location.search).filter ?  queryString.parse(this.props.location.search).filter : 'all' ,
    cat:  queryString.parse(this.props.location.search).cat ? queryString.parse(this.props.location.search).cat : 'all',
    currentPage: queryString.parse(this.props.location.search).page ? queryString.parse(this.props.location.search).page : 1
  };

  fetchUsers() {
  


      fetch(`${urlapi}/api/items/${this.state.get}/${this.state.cat}/${this.state.currentPage}`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          isLoading: false,
          users: data,
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));


     
  }

 

  nextPage =(g,cat,n)=>{

    if(n == undefined){
       n = '1';
      }

      if(g == undefined){
        g = 'all';
       }
       if(cat == undefined){
         cat = 'all';
       }
    // console.log('tttttttttttttttttttttttttttttttttt '+this.state.get + this.state.cat)
    fetch(`${urlapi}/api/items/${g}/${cat}/${n}`)
      .then(response => response.json())
      .then(data =>
        this.setState({     
          isLoading: false,
          users: data,  
          get:  g ?  g : 'all' ,
          cat:  cat ? cat : 'all',
          currentPage : n,
         })
      )
      .catch(error => this.setState({ error, isLoading: false }));

  }


   

  componentDidMount() {
  console.log(this.props.location.search);
    const values = queryString.parse(this.props.location.search)
  console.log(values.filter) // "top"
  console.log(values.origin) // "im"
  //   if (values.filter == 'netflix'){
  
  //   this.fetchUsers();
  //   const id = this.props.match.params.id;
  //   // console.log(id);
  // }else if(values.filter == ''){
  //   this.fetchUserst();
  // }
  this.fetchUsers();
}


//     componentWillReceiveProps(nextProps) {
   

//     if(nextProps.match.params.id == undefined){
//       this.setState({
//         get :  'all',
//         cat : 'all',
//     });
 
//      }else{ 
      
//       this.setState({
//         get : nextProps.match.params.id,
//         cat : nextProps.match.params.cat,
//     });

//     }


//     // this.nextPage(nextProps.match.params.page);
//     // console.log('next'+nextProps.match.params.page);

// }


componentWillReceiveProps(nextProps) {

  console.log(this.props)
const values = queryString.parse(this.props.location.search)
console.log(values.filter) // "top"

const v2 = queryString.parse(nextProps.location.search)
console.log('nextttttttttttttttttttttttttttttt'+ v2.filter)

this.setState({
  isLoading: true,
})

// this.fetchUser(v2.filter);
this.nextPage(v2.filter , v2.cat , v2.page);

 

if(v2.filter == undefined){
 
 fetch(`${urlapi}/api/items/all/all/1`)
 .then(response => response.json())
 .then(data =>
   this.setState({
     users: data,
     isLoading: false,
     cat:'all',
     get:'all',
     currentPage: 1
   })
 )
 .catch(error => this.setState({ error, isLoading: false }));
}


// this.nextPage(nextProps.match.params.page);
// console.log('next'+nextProps.match.params.page);

}

  // componentDidUpdate(){
  //   this.fetchUsers();
  // }

//   componentWillReceiveProps(nextProps) {
//     this.setState({
//         currentPage : nextProps.match.params.page
//     });

//     this.nextPage(nextProps.match.params.page);
//     console.log('next'+nextProps.match.params.page);

// }
   render(){
    const { isLoading, users, error } = this.state;


    console.log(this.props.location.search);
    const values = queryString.parse(this.props.location.search)
  console.log(values.filter) // "top"
  console.log(values.origin) // "im"
     
// {console.log( 'hi'+ this.state.users.count)}
// {console.log(this.props.match.params.page + 'state' + this.state.currentPage)}
const li =[];
const numberPages = Math.ceil(this.state.users.count /  3 );
// console.log(numberPages)

for (let i = 1; i <=  numberPages; i++) {

  let active = this.state.currentPage == i ? 'active' : '';
   
li.push(<li className={'page-item ' + active} key={i}   ><Link className="page-link" to={'/test?filter='+this.state.get +'&cat='+ this.state.cat+'&page='+i}>{i}</Link></li>)
  
}

  return (
     <div>

 {/* {this.id == undefined ? 'yet' : 'nan'} */}

 {/* {this.state.get != undefined ? 'yet' : 'nan'} */}


 {/* <ul>
          <li>
            <Link to="/test/?filter=netflix"   >Netflix</Link>
          </li>
          <li>
            <Link to="/test/?filter=zillow-group">Zillow Group</Link>
          </li>
          <li>
            <Link to="/test/?filter=yahoo">Yahoo</Link>
          </li>
          <li>
            <Link to="/test/" onClick={()=> this.fetchUserst()}>Modus Create</Link>
          </li>
        </ul> */}

 {/* { values.filter == 'netflix' ? (

   ()=> this.fetchUsers()
         
      ) : (
        ()=> this.fetchUserst()
      )} */}

 
<div>
  {/* Home */}
  <div className="home">
    <div className="home_background parallax-window" data-parallax="scroll" data-image-src="images/about_background.jpg" />
    <div className="home_content">
      <div className="home_title">Annuaire</div>
    </div>
  </div>



   {/* Classic tabs */}
<div className="classic-tabs">
  <ul className="nav tabs-cyan" id="myClassicTab" role="tablist">
    <li style={{margin : "auto"}} className="nav-item">
      <a className="nav-link  waves-light active show" id="profile-tab-classic" data-toggle="tab" href="#profile-classic" role="tab" aria-controls="profile-classic" aria-selected="true"><i className="fa fa-cutlery" aria-hidden="true"></i> Recherche</a>
    </li>
    {/* <li className="nav-item">
      <a className="nav-link waves-light" id="follow-tab-classic" data-toggle="tab" href="#follow-classic" role="tab" aria-controls="follow-classic" aria-selected="false">Follow</a>
    </li>
    <li className="nav-item">
      <a className="nav-link waves-light" id="contact-tab-classic" data-toggle="tab" href="#contact-classic" role="tab" aria-controls="contact-classic" aria-selected="false">Contact</a>
    </li>
    <li className="nav-item">
      <a className="nav-link waves-light" id="awesome-tab-classic" data-toggle="tab" href="#awesome-classic" role="tab" aria-controls="awesome-classic" aria-selected="false">Be awesome</a>
    </li> */}
  </ul>
  <div className="tab-content border-right border-bottom border-left rounded-bottom" id="myClassicTabContent">
    <div className="tab-pane fade active show" id="profile-classic" role="tabpanel" aria-labelledby="profile-tab-classic">
    <div className="container">
    <div className="search_panel active">
          <div id="search_form_1" className="search_panel_content d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-lg-between justify-content-start">


          <div className="item-search">
  <div className="search_item"><div>De quoi avez-vous besoin ?</div><input type="text" onChange={(e)=>{this.setState({ g: e.target.value})}} placeholder="Quoi, Qui : hôtel avec piscine, Restaurant, ..." className="destination search_input" required /></div>
  <div className="search_item"><div>Wilaya</div><select style={{textTransform: "capitalize" }} onChange={(e)=>{this.setState({ c : e.target.value})}} name="children" id="children_1" className="dropdown_item_select search_input"> 
  
  <option value="all" selected>Wilayas</option>
  <option> adrar </option>     <option>chlef</option>       <option>laghouat</option>       <option>oum bouaghi</option>      <option>batna</option>       <option>bejaia</option>        <option>biskra</option>    <option>bechar</option>    <option>blida</option>      <option>bouira</option>  <option> tamanrasset</option>  <option>tebessa</option> <option>tlemcen</option>  <option>tiaret</option>  <option> tizi ouzou</option>  <option>alger</option>  <option>djelfa</option>
   <option>jijel</option>
   <option>setif</option>

   <option>saida</option>
   <option>skikda</option>
   <option>sidi bel abbes</option>
   <option>annaba</option>
   <option>guelma</option>
   <option>constantine</option>
   <option>medea</option>
   <option>mostaganem</option>
   <option>msila</option>
   <option>mascara</option>
   <option>ouargla</option>
   <option>oran</option>
   <option>el baydh</option>

   <option>illizi</option>
   <option>bordj bou arreridj</option>
   <option>boumerdes</option>
   <option>el taref</option>
   <option>tindouf</option>
   <option>tissemsilt</option>
   <option>el oued</option>
   <option>khenchla</option>

   <option>souk ahrass</option>
   <option>tipaza</option>
   <option>mila</option>
   <option>aïn defla</option>
   <option>naama</option>
   <option>ain temouchent</option>
  <option>ghardaia</option>
  <option>relizane</option>

                                                                   
  
  </select></div>
</div>

            {
              this.state.g !== "" ? (
      <button className="button search_button"><Link to= {'/test?filter=' +  this.state.g +'&cat='+ this.state.c}  >Recherche<span /><span /><span /></Link></button>
) : ''
            }
          </div>
        </div>
</div>
    </div>
    <div className="tab-pane fade" id="follow-classic" role="tabpanel" aria-labelledby="follow-tab-classic">
    <div className="container">
    <div className="search_panel active">
          <form id="search_form_1" className="search_panel_content d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-lg-between justify-content-start">


          <div className="item-search">
  <div className="search_item"><div>De quoi avez-vous besoin ?</div><input type="text" onChange={(e)=>{this.setState({ get: e.target.value})}} placeholder="Quoi, Qui : hôtel avec piscine, Restaurant, ..." className="destination search_input" required /></div>
  <div className="search_item"><div>Wilaya</div><select onChange={(e)=>{this.setState({ cat : e.target.value})}} name="children" id="children_1" className="dropdown_item_select search_input"><option>Alger</option><option>Oran</option><option>Skikda</option><option>Annaba</option><option>Bejaia</option></select></div>
</div>

            
      <button className="button search_button"><Link to= {'/test/' +  this.state.get +'/'+ this.state.cat} onClick={()=> this.nextPage(1)} >Recherche<span /><span /><span /></Link></button>

          </form>
        </div>
</div>
    </div>
    <div className="tab-pane fade" id="contact-classic" role="tabpanel" aria-labelledby="contact-tab-classic">
      <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
        deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
        provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
        Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est
        eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
        assumenda est, omnis dolor repellendus. </p>
    </div>
    <div className="tab-pane fade" id="awesome-classic" role="tabpanel" aria-labelledby="awesome-tab-classic">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
        eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.</p>
    </div>
  </div>
</div>
{/* Classic tabs */}


  {/* Offers */}
  <div className="offers">



    {/* Offers */}
    <div className="container">
      <div className="row">
        <div className="col-lg-1 temp_col" />
        <div className="col-lg-11">
          {/* Offers Sorting */}
          <div className="offers_sorting_container">
            {/* <ul className="offers_sorting">
              <li>
                <span className="sorting_text">price</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;original-order&quot; }" data-parent=".price_sorting"><span>show all</span></li>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;price&quot; }" data-parent=".price_sorting"><span>ascending</span></li>
                </ul>
              </li>
              <li>
                <span className="sorting_text">location</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;original-order&quot; }"><span>default</span></li>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;name&quot; }"><span>alphabetical</span></li>
                </ul>
              </li>
              <li>
                <span className="sorting_text">stars</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="filter_btn" data-filter="*"><span>show all</span></li>
                  <li className="sort_btn" data-isotope-option="{ &quot;sortBy&quot;: &quot;stars&quot; }"><span>ascending</span></li>
                  <li className="filter_btn" data-filter=".rating_3"><span>3</span></li>
                  <li className="filter_btn" data-filter=".rating_4"><span>4</span></li>
                  <li className="filter_btn" data-filter=".rating_5"><span>5</span></li>
                </ul>
              </li>
              <li className="distance_item">
                <span className="sorting_text">distance from center</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="num_sorting_btn"><span>distance</span></li>
                  <li className="num_sorting_btn"><span>distance</span></li>
                  <li className="num_sorting_btn"><span>distance</span></li>
                </ul>
              </li>
              <li>
                <span className="sorting_text">reviews</span>
                <i className="fa fa-chevron-down" />
                <ul>
                  <li className="num_sorting_btn"><span>review</span></li>
                  <li className="num_sorting_btn"><span>review</span></li>
                  <li className="num_sorting_btn"><span>review</span></li>
                </ul>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="col-lg-12">
          {/* Offers Grid */}
          <div className="offers_grid">
            {/* Offers Item */}

            <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        {!isLoading ? (
          users.data.map(user => {
            const { id, name,resum,mone,mail, img ,adr,tel,user_id} = user;
            return (
              <div key={id}>
                {/* <p>Name: {name}</p>
                <p>Email Address: {email}</p>
                <hr /> */}



                <div className="offers_item rating_4">
              <div className="row">
                <div className="col-lg-1 temp_col" />
                <div className="c-mob col-lg-3 col-sm-5 col-md-4">
                  <div className="offers_image_container">
                    {/* Image by https://unsplash.com/@kensuarez */}
                    <div className="offers_image_background" style={{backgroundImage: 'url('+ (img != null ?  urlapi+'/images/news/' + img : 'https://i.stack.imgur.com/y9DpT.jpg') + ')'}} />
                    <div className="offertm_name"><a href="single_listing.html"> { mone} </a></div>
                  </div>
                </div>
                <div className="c-r-mob col-lg-8 col-md-8 col-sm-7">
                  <div className="offers_content">
            <div className="offers_price">{ name}</div>
                    <div className="rating_r rating_r_4 offers_rating" data-rating={4}>
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                    </div>
            <p className="offers_text"> {resum.slice(0,280) + "....."}</p>
                     
                    <div className="button b-mob book_button"><a href={"/page/"+ user_id}>Voir Plus<span /><span /><span /></a></div>
                    <div className="offertm_reviews">
                      <div className="offertm_reviews_content">
                        {/* <div className="offertm_reviews_title">very good</div> */}
                        <div className="offertm_reviews_subtitle"> {this.state.users.comment.filter((obj) => obj.c_id === user_id).length} Commentaire</div>
                      </div>
                      {console.log('------------------>'           + this.state.users.comment.filter((obj) => obj.c_id === user_id).reduce(function(tot, arr) { 
  // return the sum with previous value
  return parseInt(tot + parseInt(arr.rate));

  // set initial value as 0
},0))}
              <div className="offertm_reviews_rating text-center"> 

              {this.state.users.comment.filter((obj) => obj.c_id === user_id).length  > 0 ? parseInt(ratelength = this.state.users.comment.filter((obj) => obj.c_id === user_id).reduce(function(tot, arr) { 
              // return the sum with previous value
              // console.log(arr.rate.length)


              return parseInt(tot + parseInt(arr.rate)) ;

              // set initial value as 0
              },0) / this.state.users.comment.filter((obj) => obj.c_id === user_id).length) : 0}
              
              {/* {users.comment.filter((obj) => obj.c_id === user_id).map( (rating, i )=> {
              //  let ratelength =0;
               ratelength[i] = parseInt(rating.rate);
               console.log(ratelength);
               return (
                  ''
               )

              })} */}
              </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


              </div>
            );
          })
        ) : (
          <h3>Loading...</h3>
        )}
      </React.Fragment>

      <nav aria-label="Page navigation example">
  <ul className="pagination pagination-circle pg-blue">

  { this.state.currentPage > 1 ?  
    
    <li className="page-item"><Link to={'/test?filter='+this.state.get +'&cat='+ this.state.cat+'&page=1'}    className="page-link">First</Link></li>



: ''}

 
    { this.state.currentPage > 1 ?  
    <li className="page-item ">
      <Link className="page-link" to={'/test?filter='+this.state.get +'&cat='+ this.state.cat+'&page='+(this.state.currentPage -1)} aria-label="Previous"  >
        <span aria-hidden="true">«</span>
        <span className="sr-only">Previous</span>
      </Link>
    </li>

: ''}
    {/* {console.log( eval( parseInt(this.state.currentPage) + 1))} */}


    { this.state.users.count > 3 ? li : ''}
    {/* <li className="page-item active"><a className="page-link">1</a></li>
    <li className="page-item"><a className="page-link">2</a></li>
    <li className="page-item"><a className="page-link">3</a></li>
    <li className="page-item"><a className="page-link">4</a></li>
    <li className="page-item"><a className="page-link">5</a></li> */}

{ this.state.currentPage < numberPages  ?  
    
<li className="page-item">
      <Link to={ '/test?filter='+this.state.get +'&cat='+ this.state.cat+'&page='+(parseInt(this.state.currentPage)+1)} className="page-link"   aria-label="Next">
        <span aria-hidden="true">»</span>
        <span className="sr-only">Next</span>
      </Link>
    </li>


: ''}

    



{ this.state.currentPage < numberPages ?  
    
         <li className="page-item"><Link to={'/test?filter='+this.state.get +'&cat='+ this.state.cat+'&page='+numberPages}    className="page-link">Last</Link></li>

    
    
    : ''}




  </ul>
</nav>

 
             
         
         
          </div>
        </div>
      </div>
    </div>		
  </div>
</div>


{console.log('state   '+this.state.get + '   ' + this.state.cat)}
{/* {console.log( this.props.match.params.id + '   ' + this.props.match.params.cat)} */}
{console.log('st props  ----- >' + queryString.parse(this.props.location.search).filter)}

{ this.props.match.params.id == undefined ? console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh') : ''
 }

 {console.log(this.state.users)}


</div>
    
  );
}
}

export default Annuaire;
